import React, { useRef, useState, useEffect } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import { Title, Line } from "../../";
import * as s from "./contacts.module.css";
import ScrollTrigger from "gsap/ScrollTrigger";

const AboutContacts = () => {
  const ref = useRef();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const trigger = ScrollTrigger.create({
      trigger: ref.current,
      start: `0% 50%`,
      end: `0% 50%`,
      once: true,
      onEnter: () => setIsReady(true)
    })
    return () => trigger.kill()
  }, [ref, setIsReady])

  const d = useStaticQuery(graphql`
    query {
      sanityAbout {
        contactTitle
        formLinkLabel
        formLinkIcon {
          ...ImageWithPreview
        }
        addresses {
          icon {
            ...ImageWithPreview
          }
          addressLine1
          addressLine2
          mapLink
          phone
        }
      }
    }
  `).sanityAbout;
  
  return (
    <section ref={ref} className={s.section}>
      <div className={s.wrapper}>
        <Title className={s.title} isReady={isReady} value={d.contactTitle} animationDelay={400} animationDuration={1000} component={"h2"} color={"var(--gray-light)"} />
        <Line className={s.line} isReady={isReady} />
        <ul className={s.content}>
          {d.addresses.map( (v, id) => <Address key={v.id} style={{transitionDelay: `${300 + 100 * id}ms`}} isReady={isReady} {...v} /> )}
          <li className={`${s.contact} ${s.contactLink} ${s.linkLabel} ${isReady ? s.ready : ""}`} style={{transitionDelay: `${300 + 100 * d.addresses.length}ms`}} >
            <div className={s.icon}>
              <Image {...d.formLinkIcon} width={64} alt="" />
            </div>
            <Link to="/contact/">{d.formLinkLabel}</Link>
          </li>
        </ul>
      </div>
    </section>
  )
}

const Address = ({icon, addressLine1, addressLine2, mapLink, phone, isReady, style}) => {
  const phoneLink = phone ? "tel:" + phone.replace(/[\s-]/g, '') : "";

  return (
    <li className={`${s.contact} ${isReady ? s.ready : ""}`} style={style}>
      <div className={s.icon} >
        <Image {...icon} width={64} alt="" />
      </div>
      <a className={s.address} href={mapLink} target="_blank" rel="noreferrer">
        <p>{addressLine1}</p>
        <p>{addressLine2}</p>
        <p className={s.linkLabel}>View on Google Maps</p>
      </a>
      {phone && <p className={`${s.phone} ${s.linkLabel}`}><a href={phoneLink}>{phone}</a></p>}
    </li>
  )
}

export default AboutContacts;