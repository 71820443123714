import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { PortableText } from "@portabletext/react";
import { Image } from "../../";

import * as s from "./content.module.css";

const AboutContent = () => {
  const {_rawBodyContent, heroImage} = useStaticQuery(graphql`
    query {
      sanityAbout {
        heroImage {
          ...ImageWithPreview
          asset {
            metadata {
              dimensions {
                aspectRatio
              }
            }
          }
        }
        _rawBodyContent
      }
    }
  `).sanityAbout;
  
  return (
    <>
      <Image
        image={heroImage}
        width={1440}
        alt=""
        />
      <section className={s.section}>
        <PortableText value={_rawBodyContent} />
      </section>
    </>
  )
}

export default AboutContent;