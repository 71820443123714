import React from "react";
import { graphql } from "gatsby";

import {Layout} from "../components";
import {Hero, Content, Contacts} from "../components/aboutPage";

const AboutPage = ({data}) => {
  const {seoTitle, seoDescription} = data.sanityAbout;
  return (
  <Layout
  seo={{
    title: seoTitle,
    description: seoDescription
  }}
  >
    <Hero />
    <Content />
    <Contacts />
  </Layout>
)}

export const pageQuery = graphql`
  query AboutPageQuery {
    sanityAbout {
      seoTitle
      seoDescription
    }
  }
`

export default AboutPage