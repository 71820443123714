// extracted by mini-css-extract-plugin
export var address = "contacts-module--address--db469";
export var contact = "contacts-module--contact--2f3a9";
export var contactLink = "contacts-module--contact-link--bfaef";
export var content = "contacts-module--content--34fa1";
export var icon = "contacts-module--icon--7ddf0";
export var line = "contacts-module--line--fee56";
export var linkLabel = "contacts-module--link-label--a8724";
export var phone = "contacts-module--phone--9274e";
export var ready = "contacts-module--ready--b5284";
export var section = "contacts-module--section--7d046";
export var title = "contacts-module--title--30757";
export var wrapper = "contacts-module--wrapper--29ad3";